import { Link, useMatch, useResolvedPath } from "react-router-dom";
import React, { useContext } from "react";
import { AuthContext } from "../Context/AuthContext"; // Import the AuthContext
import "./navbar.css";
import Logo from "../../public/mytube.svg";
import Notification from "./Chat/Notification";

const beURL = import.meta.env.VITE_BEURL;

export default function Navbar() {
  const { user, updateUser } =useContext(AuthContext); // Access user and updateUser from context

  // Logout function
  const logout = async () => {
    updateUser(null); // Clear the user from context
    window.open(beURL + "/logout", "_self");
  };

  return (
    <nav className="nav">
      <img src={Logo} alt="Logo" />

      {user ? (
        <>
          <h1 style={{ color: "gold", fontWeight: "bold" }}>
            {user.displayName}
          </h1>
          {user.image && <img src={user.image} className="profile" alt="User" />}
        </>
      ) : (
        ""
      )}

      <ul>
        {/* Always display Privacy Policy */}
        <CustomLink
          to="#"
          onClick={() => {
            window.open("/privacy-policy.html", "_blank"); // Open Privacy Policy in a new tab
          }}
        >
          Privacy Policy
        </CustomLink>

        {/* Conditional Links: Display login/logout and dashboard if user is logged in */}
        {user ? (
          <>
            <CustomLink to="/dashboard">Dashboard</CustomLink>
            <CustomLink to="/chat">My Chat</CustomLink>
            <Notification />
            <CustomLink to="" onClick={logout}>
              Logout
            </CustomLink>
          </>
        ) : (
          <CustomLink to="/login">Login</CustomLink>
        )}
      </ul>
    </nav>
  );
}

function CustomLink({ to, children, ...props }) {
  const resolvedPath = useResolvedPath(to);
  const isActive = useMatch({ path: resolvedPath.pathname, end: true });

  return (
    <li className={isActive ? "active" : ""}>
      <Link to={to} {...props}>
        {children}
      </Link>
    </li>
  );
}
