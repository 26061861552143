import { useContext, useState } from "react";
import { ChatContext } from "../../Context/ChatContext";
import { AuthContext } from "../../Context/AuthContext";
import { Stack } from "react-bootstrap"; // Import Stack from react-bootstrap

const PotentialChats = () => {
  const { user } = useContext(AuthContext);
  const { potentialChats, createChat, onlineUsers } = useContext(ChatContext);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortOrder, setSortOrder] = useState("asc"); // Ascending by default
  const [sortOnlineFirst, setSortOnlineFirst] = useState(false); // Flag to toggle online users at top

  // Filter users based on the search query
  const filteredChats = potentialChats.filter((u) =>
    u.displayName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Function to handle sorting alphabetically
  const handleSort = () => {
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };

  // Function to handle sorting online users to the top
  const handleSortByOnline = () => {
    setSortOnlineFirst(!sortOnlineFirst); // Toggle the flag
  };

  // Sort the users alphabetically
  const sortedChats = filteredChats.sort((a, b) => {
    if (sortOrder === "asc") {
      return a.displayName.localeCompare(b.displayName); // Sort alphabetically in ascending order
    } else {
      return b.displayName.localeCompare(a.displayName); // Sort alphabetically in descending order
    }
  });

  // If sortOnlineFirst is true, prioritize online users first, then alphabetically sort
  const sortedByOnlineFirst = sortOnlineFirst
    ? sortedChats.sort((a, b) => {
        const aOnline = onlineUsers.some(
          (onlineUser) => onlineUser.userId === a._id
        );
        const bOnline = onlineUsers.some(
          (onlineUser) => onlineUser.userId === b._id
        );
        if (aOnline && !bOnline) return -1; // a is online, b is offline
        if (!aOnline && bOnline) return 1; // a is offline, b is online
        return a.displayName.localeCompare(b.displayName); // Sort alphabetically when both are either online or offline
      })
    : sortedChats; // If not sorting by online first, use the default sortedChats

  return (
    <div>
      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search Users"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="search-bar"
      />

      {/* Sort Buttons */}
      <button onClick={handleSort}>
        Sort {sortOrder === "asc" ? "Descending" : "Ascending"}
      </button>
      <button onClick={handleSortByOnline}>Sort By Online</button>

      {/* Users List with Scroll (using Stack for vertical display) */}
      <div
        className="all-users"
        style={{
          maxHeight: "70vh", // Ensure it takes up 70% of the screen height
          //overflowY: "scroll", // Vertical scroll if content overflows
          padding: "10px", // Padding around the list
          border: "1px solid #ccc", // Border around the list for styling
          borderRadius: "8px", // Rounded corners for the list
          marginBottom: "20px", // Spacing below the list
        }}
      >
        <Stack
          gap={3}
          style={{
            overflowY: "scroll", // Vertical scroll if content overflows
          }}
        >
          {sortedByOnlineFirst.map((u, index) => {
            const isOnline = onlineUsers?.some(
              (onlineUser) => onlineUser.userId === u._id
            );
            return (
              <div
                className="single-user"
                key={index}
                onClick={() => createChat(user._id, u._id)}
                style={{
                  cursor: "pointer", // Pointer cursor on hover
                  display: "block", // Ensures that the name and status are stacked vertically
                }}
              >
                <div>{u.displayName}</div> {/* Display the user name */}
                <span
                  className={isOnline ? "user-online" : ""}
                  style={{
                    marginTop: "5px", // Add margin on top to separate the online status
                    width: "8px", // Small width for the green sphere
                    height: "8px", // Small height for the green sphere
                    borderRadius: "50%", // Circle shape for online indicator
                    backgroundColor: isOnline ? "green" : "transparent", // Green if online
                  }}
                ></span>
              </div>
            );
          })}
        </Stack>
      </div>
    </div>
  );
};

export default PotentialChats;
