import { useContext, useEffect, useState } from "react";
import { ChatContext } from "../Context/ChatContext";
import { beURL, getRequest } from "../Utils/services";

export const useFetchLatestMessage = (chat) => {
  const { newMessage, notifications } = useContext(ChatContext);
  const [latestMessage, setLatestMessage] = useState(null);

  useEffect(() => {
    const getMessages = async () => {
        const response = await getRequest(`${beURL}/messages/${chat._id}`);
        if(response.error){
            return console.log("Error in fetching messages...", response.error);
        }
        const lastMessage=response[response.length-1];
        setLatestMessage(lastMessage);
    };
    getMessages();
  },[newMessage, notifications, chat._id]);
  return { latestMessage };
};
