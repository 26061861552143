import { useContext } from "react";
import { Container, Stack } from "react-bootstrap";
import { ChatContext } from "../Context/ChatContext";
import { AuthContext } from "../Context/AuthContext";
import UserChat from "../Components/Chat/UserChat";
import PotentialChats from "../Components/Chat/PotentialChats";
import ChatBox from "../Components/Chat/ChatBox";

const Chat = () => {
  const { user } = useContext(AuthContext);
  const { userChats, isUserChatLoading, updateCurrentChat } =
    useContext(ChatContext);

  return (
    <Container>
      {/* Stack direction is horizontal to display both PotentialChats and UserChats side by side */}
      <Stack direction="horizontal" gap={4} className="align-items-start">
        {/* PotentialChats displayed vertically and with a scrollbar if necessary */}
        <div style={{ maxHeight: "70vh", overflowY: "scroll", width: "300px" }}>
          <PotentialChats />
        </div>

        {/* UserChats displayed in a column (stacked vertically) with a vertical scrollbar if content exceeds height */}
        {userChats?.length < 1 ? null : (
          <div
            style={{
              maxHeight: "70vh", // Limiting the height of the UserChats section to 70% of the viewport height
              width: "450px",
              overflowY: "auto", // Enable vertical scrolling if content exceeds max height
            }}
          >
            <Stack direction="vertical" gap={3} className="align-items-start"  style={{paddingTop: "10px",
            }}>
              {isUserChatLoading && <p>Loading Chats...</p>}
              {userChats?.map((chat, index) => (
                <div key={index} onClick={() => updateCurrentChat(chat)}>
                  <UserChat chat={chat} user={user} />
                </div>
              ))}
            </Stack>
          </div>
        )}
      </Stack>

      <div
        style={{
          marginTop: "5px",
          maxHeight: "500px", // Limiting the height of the UserChats section to 70% of the viewport height
          width: "675px",
          overflowY: "auto", // Enable vertical scrolling if content exceeds max height
        }}
      >
        <ChatBox />
      </div>
    </Container>
  );
};

export default Chat;
