import { useEffect, useState } from "react";
import { beURL, getRequest } from "../Utils/services";

export const useFetchRecipientUser = (chat, user) => {
  const [recipientUser, setRecipientUser] = useState(null);
  const [erroe, setError] = useState(null);
  const recipientId = chat?.members.find((id) => id !== user?._id);

  useEffect(() => {
    const getUser = async () => {
      if (!recipientId) return null;
      const response = await getRequest(`${beURL}/users/find/${recipientId}`);

      if (response.error) {
        return setError(response.error);
      }
      setRecipientUser(response);
    };
    getUser();
  }, [recipientId]);

  return { recipientUser };
};
