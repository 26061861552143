import { useFetchRecipientUser } from "../../hooks/useFetchRecipient";
import { useFetchLatestMessage } from "../../hooks/useFetchLatestMessage";
import { Stack } from "react-bootstrap";
import avatar from "../../assets/avatar.svg";
import { useContext } from "react";
import { ChatContext } from "../../Context/ChatContext";
import { unreadNotificationsFunc } from "../../Utils/unreadNotifications";
import moment from "moment";

const UserChat = ({ chat, user }) => {
  const { recipientUser } = useFetchRecipientUser(chat, user);
  const { onlineUsers, notifications, markThisUserNotificationAsRead } =
    useContext(ChatContext);
  const { latestMessage } = useFetchLatestMessage(chat);
  const unreadNotifications = unreadNotificationsFunc(notifications);
  const thisUserNotifications = unreadNotifications?.filter(
    (n) => n.senderId === recipientUser?._id
  );

  const isOnline = onlineUsers?.some((u) => u?.userId === recipientUser?._id);
  
  const truncatedText = (text) => {
    let shortText = text.substring(0, 20);
    if (text.length > 20) {
      shortText = shortText + "...";
    }
    return shortText;
  };

  return (
    <Stack
      direction="horizontal"
      gap={3}
      className="user-card align-items-center p-2 justyfy-content-between"
      role="button"
      onClick={() => {
        if (thisUserNotifications?.length !== 0) {
          markThisUserNotificationAsRead(thisUserNotifications, notifications);
        }
      }}
    >
      <div className="d-flex">
        <div className="me-2">
          <img src={avatar} height="35px"></img>
        </div>
        <div className="text-content">
          <div className="name">{recipientUser?.displayName}</div>
          <div className="text">
            {latestMessage?.text && (
              <span>{truncatedText(latestMessage?.text)}</span>
            )}
          </div>
        </div>
      </div>
      <div className="d-flex flex-column align-items-end"></div>
      <div className="date">{moment(latestMessage?.createAt).calendar()}</div>
      <div
        className={
          thisUserNotifications?.length > 0 ? "this-user-notifications" : ""
        }
      ></div>
      {thisUserNotifications?.length > 0 ? thisUserNotifications?.length : ""}
      <div className={isOnline ? "user-online" : ""}></div>
    </Stack>
  );
};

export default UserChat;
