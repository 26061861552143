import { createContext, useState, useEffect, useCallback } from "react";
import { beURL, postRequest, getRequest } from "../Utils/services";
import { io } from "socket.io-client";


export const ChatContext = createContext();

export const ChatContextProvider = ({ children, user }) => {
  const [userChats, setUserChats] = useState([]);
  const [isUserChatLoading, setIsUserChatLoading] = useState(false);
  const [userChaterror, setUserChaterror] = useState(null);
  const [isChatLoading, setIsChatLoading] = useState(false);
  const [potentialChats, setPotentialChats] = useState([]);
  const [currentChat, setCurrentChat] = useState(null);
  const [messages, setMessages] = useState(null);
  const [isMessageLoading, setIsMessageLoading] = useState(false);
  const [messageError, setMessageEror] = useState(null);
  const [sendTextMessageError, setSendTextMessageError] = useState(null);
  const [newMessage, setNewMessage] = useState(null);
  const [socket, setSocket] = useState(null);
  const [onlineUsers, setOnlineUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [allUsers, setAllUsers] = useState([]);

  const beSocketURL = import.meta.env.VITE_BEURL_SOCKET;

  useEffect(() => {
    if (!user) return; // Ensure user is defined
  
    // Explicitly use wss:// for WebSocket
    const newSocket = io("wss://api.mytube.co.in", {
      withCredentials: true, // Allow cookies to be sent with the WebSocket connection
    });
  
    setSocket(newSocket);
  
    return () => {
      newSocket.disconnect();
    };
  }, [user]);

  //Add online users
  useEffect(() => {
    if (socket && user?._id) {
      socket.emit("addNewUser", user._id);
      socket.on("getOnlineUsers", (res) => {
        console.log("OnlineUsers in Socket", res);
        setOnlineUsers(res);
      });
    }
  }, [socket, user]);

  //Send Message

  useEffect(() => {
    if (socket && newMessage && currentChat) {
      const recipientId = currentChat.members.find((id) => id !== user?._id);
      if (recipientId) {
        socket.emit("sendMessage", { ...newMessage, recipientId });
      }
    }
  }, [newMessage, currentChat, socket, user]);

  //Receive Messages and notifications
  useEffect(() => {
    if (socket) {
      socket.on("getMessage", (res) => {
        if (currentChat?._id !== res.chatId) return; // Make sure you're using `_id` and `chatId` correctly
        setMessages((prev) => [...prev, res]);
      });
      socket.on("getNotification", (res) => {
        const isChatOpen = currentChat?.members.some(
          (id) => id === res.senderId
        );

        if (isChatOpen) {
          setNotifications((prev) => [{ ...res, isRead: true }, ...prev]);
        } else {
          setNotifications((prev) => [res, ...prev]);
        }
      });
      return () => {
        socket.off("getMessage"); // Clean up the listener when component unmounts
        socket.off("getNotification"); // Clean up the listener when component unmounts
      };
    }
  }, [socket, currentChat]);

  useEffect(() => {
    const getUsers = async () => {
      const response = await getRequest(`${beURL}/users`);
      if (response.error) {
        return console.log("Error in fetching users", response.error);
      }
      const pChats = response.filter((u) => {
        let isChatCreated = false;
        if (user?._id === u._id) return false;

        if (userChats) {
          isChatCreated = userChats?.some((chat) => {
            return chat.members[0] === u._id || chat.members[1] === u._id;
          });
        }
        return !isChatCreated;
      });
      setPotentialChats(pChats);
      setAllUsers(response);
    };
    getUsers();
  }, [userChats]);

  useEffect(() => {
    const getUserChats = async () => {
      if (user?._id) {
        setIsUserChatLoading(true);
        setUserChaterror(null);
        const response = await getRequest(`${beURL}/chats/${user?._id}`);
        setIsUserChatLoading(false);
        if (response.error) {
          return setUserChaterror(response.message);
        } else {
          setUserChats(response);
        }
      }
    };
    getUserChats();
  }, [user, notifications, socket]);

  const createChat = useCallback(async (firstId, secondId) => {
    try {
      const response = await postRequest(
        `${beURL}/chats`,
        { firstId, secondId } // Pass as an object
      );

      if (response.error) {
        return console.log("Error in creating user chat", response.error);
      }
      console.log("Chat Created between", firstId, secondId);
      setUserChats((prev) => [...prev, response]);
      console.log("UserChat in createChat in ChatContext", userChats);
    } catch (error) {
      console.log("Error in createChat:", error);
    }
  }, []);

  useEffect(() => {
    const getMessages = async () => {
      setIsMessageLoading(true);
      setMessageEror(null);
      console.log("Get Messages URL", `${beURL}/messages/${currentChat?._id}`);
      const response = await getRequest(
        `${beURL}/messages/${currentChat?._id}`
      );
      setIsMessageLoading(false);
      if (response.error) {
        return setMessageEror(response);
      }
      setMessages(response);
    };
    getMessages();
  }, [currentChat]);

  const sendTextMessage = useCallback(
    async (textMessage, sender, currentChatId, setTextMessage) => {
      if (!textMessage) return console.log("You must type something");
      const response = await postRequest(`${beURL}/messages`, {
        chatId: currentChatId,
        senderId: sender._id,
        text: textMessage,
      });
      if (response.error) {
        return setSendTextMessageError(response);
      }

      setNewMessage(response);
      setMessages((prev) => [...prev, response]);
      setTextMessage("");
    },
    []
  );

  const updateCurrentChat = useCallback((chat) => {
    setCurrentChat(chat);
    console.log("CurrentChat in updateCurrentChat----->", currentChat);
  });

  const markAllNotificationAsRead = useCallback((notifications) => {
    const mNotifications = notifications.map((n) => {
      return { ...n, isRead: true };
    });
    setNotifications(mNotifications);
  }, []);

  const markNotificationAsRead = useCallback(
    (n, userChat, user, notifications) => {
      const desiredChat = userChats.find((chat) => {
        const chatMembers = [user._id, n.senderId];
        const isDesiredChat = chat?.members.every((member) => {
          return chatMembers.includes(member);
        });
        return isDesiredChat;
      });
      const mNotifications = notifications.map((el) => {
        if (n.secondId === el.senderId) {
          return { ...n, isRead: true };
        } else {
          return el;
        }
      });
      updateCurrentChat(desiredChat);
      setNotifications(mNotifications);
    },
    []
  );

  const markThisUserNotificationAsRead = useCallback(
    (thisUserNotifications, notifications) => {
      const mNotifications = notifications.map(el => {
        let notifications;
        thisUserNotifications.forEach((n) => {
          if (n.senderId === el.senderId) {
            notifications = { ...n, isRead: true };
          } else {
            notifications = el;
          }
        });
        return notifications;
      });
      setNotifications(mNotifications);
    },
    []
  );

  return (
    <ChatContext.Provider
      value={{
        userChats,
        isUserChatLoading,
        userChaterror,
        isChatLoading,
        potentialChats,
        createChat,
        currentChat,
        updateCurrentChat,
        messages,
        isMessageLoading,
        messageError,
        sendTextMessage,
        onlineUsers,
        notifications,
        allUsers,
        markAllNotificationAsRead,
        markNotificationAsRead,
        markThisUserNotificationAsRead,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
