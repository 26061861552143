import React, { useState } from "react";
import "./videotable.css";
import axios from "axios";
import Cookies from "js-cookie";

const VideoTable = ({ videos }) => {
  const [sortOrder, setSortOrder] = useState("desc");
  const [isUploadConfirmationVisible, setIsUploadConfirmationVisible] =
    useState(false); // Flag to show upload confirmation
  const [isDeleteConfirmationVisible, setIsDeleteConfirmationVisible] =
    useState(false); // Flag to show delete confirmation
  const [isUploading, setIsUploading] = useState(false); // Flag to indicate uploading status
  const [videoFile, setVideoFile] = useState(null); // Store the selected video file
  const [videoDetails, setVideoDetails] = useState({
    title: "",
    description: "",
    tags: "",
  });
  const [videoToDelete, setVideoToDelete] = useState(null); // Store video to delete
  const [showUploadForm, setShowUploadForm] = useState(false); // Flag to show the upload form
  const [uploadButtonText, setUploadButtonText] = useState("Upload Video"); // Text for the upload button
  const [privacyStatus, setPrivacyStatus] = useState("Public"); // Store privacy status (Private/Public)
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [uploadProgress, setUploadProgress] = useState(0); 
  const beURL = import.meta.env.VITE_BEURL;

  // Handle the file change event
  const handleFileChange = (event) => {
    setVideoFile(event.target.files[0]);
  };

  // Handle the confirmation of upload after clicking the Upload button
  const handleUploadButtonClick = () => {
    // Check if a file is selected
    if (!videoFile) {
      alert("Please select a video file.");
      return; // Don't proceed if no file is selected
    }
    setIsUploadConfirmationVisible(true); // Show confirmation popup when Upload is clicked
  };

  // Handle the confirmation of upload in the popup
  const handleUploadConfirm_Old = async () => {
    if (!videoFile) {
      alert("Please select a video file.");
      return;
    }

    const { title, description, tags } = videoDetails;

    const formData = new FormData();
    formData.append("video", videoFile);
    formData.append("title", title);
    formData.append("description", description);
    formData.append("tags", tags);
    formData.append("privacyStatus", privacyStatus); // Add privacy status to form data

    setIsUploading(true); // Set uploading status to true

    try {
      const response = await axios.post(beURL + "/uploadVideo", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        withCredentials: true,
      });

      alert(
        "Video uploaded successfully! View here: " + response.data.videoUrl
      );
      setIsUploadConfirmationVisible(false); // Hide confirmation popup
      setIsUploading(false); // Set uploading status to false
      setVideoFile(null); // Reset video file after upload
      setVideoDetails({ title: "", description: "", tags: "" }); // Reset video details
    } catch (error) {
      console.error("Error uploading video:", error);
      alert("Error uploading video.");
      setIsUploading(false); // Reset uploading status
    }
  };

  const handleUploadConfirm = async () => {
    if (!videoFile) {
      alert("Please select a video file.");
      return;
    }

    setIsUploading(true);
    setUploadProgress(0);

    try {
      const tokenResponse = await axios.get(beURL + "/getYoutubeToken", {
        withCredentials: true,
      });

      const token = tokenResponse.data.token;
      if (!token) {
        alert("Failed to retrieve authentication token. Please log in again.");
        setIsUploading(false);
        return;
      }

      const { title, description, tags } = videoDetails;
      const metadata = {
        snippet: {
          title: title || "Untitled Video",
          description: description || "Uploaded via YouTube API",
          tags: tags ? tags.split(",") : [],
          categoryId: "22",
        },
        status: {
          privacyStatus: privacyStatus.toLowerCase(),
        },
      };

      const initRes = await axios.post(
        "https://www.googleapis.com/upload/youtube/v3/videos?uploadType=resumable&part=snippet,status",
        metadata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
            "X-Upload-Content-Type": videoFile.type,
            "X-Upload-Content-Length": videoFile.size,
          },
        }
      );

      let uploadUrl = initRes.headers.location;
      if (!uploadUrl) {
        throw new Error("Failed to get upload URL.");
      }

      const CHUNK_SIZE = 100 * 1024 * 1024; // 100MB per chunk
      let start = 0;
      const totalSize = videoFile.size;
      let videoId = null;

      while (start < totalSize) {
        const chunk = videoFile.slice(start, start + CHUNK_SIZE);
        const end = Math.min(start + CHUNK_SIZE, totalSize) - 1;

        try {
          const chunkRes = await axios.put(uploadUrl, chunk, {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Range": `bytes ${start}-${end}/${totalSize}`,
              "Content-Type": videoFile.type,
            },
            maxRedirects: 0,
            validateStatus: (status) => status >= 200 && status < 400,
            onUploadProgress: (progressEvent) => {
              const progress = Math.round(((start + progressEvent.loaded) / totalSize) * 100);
              setUploadProgress(progress);
            },
          });

          if (chunkRes.status === 200) {
            videoId = chunkRes.data.id;
            console.log("Uploaded video ID:", videoId);
          }

          if (chunkRes.status === 308) {
            uploadUrl = chunkRes.headers.location || uploadUrl;
          }
        } catch (err) {
          console.error("Error uploading chunk:", err);
          throw err;
        }

        start += CHUNK_SIZE;
        setUploadProgress(Math.round((start / totalSize) * 100));
      }

      if (videoId) {
        await axios.post(beURL + "/saveVideo", { videoId }, { withCredentials: true });
        alert("Video uploaded and saved successfully!");
      } else {
        alert("Upload completed, but video ID is missing.");
      }

      setIsUploadConfirmationVisible(false);
      setIsUploading(false);
      setVideoFile(null);
      setVideoDetails({ title: "", description: "", tags: "" });
      setUploadProgress(0);

    } catch (error) {
      console.error("Error uploading video:", error);
      alert("Upload failed. Please try again.");
      setIsUploading(false);
      setUploadProgress(0);
    }
  };
  
  

  // Handle the cancel upload action in the popup
  const handleUploadCancel = () => {
    setIsUploadConfirmationVisible(false);
  };

  // Handle the delete button click (no changes here)
  const handleDeleteClick = (videoUrl) => {
    const videoId = extractVideoId(videoUrl);
    setVideoToDelete(videoId);
    setIsDeleteConfirmationVisible(true);
  };

  // Handle delete confirmation (no changes here)
  const handleDeleteConfirm = async () => {
    if (!videoToDelete) {
      alert("No video selected for deletion");
      return;
    }

    try {
      const response = await axios.delete(
        beURL + `/deleteVideo/${videoToDelete}`,
        {
          withCredentials: true,
        }
      );
      if (response.data.success) {
        alert("Video deleted successfully");
        setIsDeleteConfirmationVisible(false);
      } else {
        alert("Failed to delete video");
        setIsDeleteConfirmationVisible(false);
      }
    } catch (error) {
      console.error("Error deleting video:", error);
      alert("Error deleting video");
      setIsDeleteConfirmationVisible(false);
    }
  };

  const handleDeleteCancel = () => {
    setIsDeleteConfirmationVisible(false);
    setVideoToDelete(null);
  };

  const sortedVideos = [...videos].sort((a, b) => {
    const dateA = new Date(a.publishedAt);
    const dateB = new Date(b.publishedAt);
    return sortOrder === "asc" ? dateA - dateB : dateB - dateA;
  });

  const handleVideoClick = (videoUrl) => {
    const videoId = extractVideoId(videoUrl);
    const embedUrl = `https://www.youtube.com/watch?v=${videoId}`;
    window.open(embedUrl, "_blank");
  };

  const extractVideoId = (url) => {
    const match = url.match(/[?&]v=([^&#]*)/);
    return match ? match[1] : null;
  };

  // Toggle the visibility of the upload form
  const toggleUploadForm = () => {
    if (showUploadForm) {
      setShowUploadForm(false);
      setUploadButtonText("Upload Video");
    } else {
      setShowUploadForm(true);
      setUploadButtonText("Cancel Upload");
    }
  };

  // Function to format date as dd/mm/yyyy
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Month is 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  // Filter videos based on search query
  const filteredVideos = sortedVideos.filter((video) =>
    video.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="video-table-container">
      <button onClick={toggleUploadForm} className="upload-video-button">
        {uploadButtonText}
      </button>

      {showUploadForm && (
        <form className="upload-form">
          <div className="form-row">
            <div className="form-column">
              <label>Title:</label>
              <input
                style={{ height: "38px" }}
                type="text"
                value={videoDetails.title}
                onChange={(e) =>
                  setVideoDetails({ ...videoDetails, title: e.target.value })
                }
                required
                className="half-width"
              />
            </div>
            <div className="form-column">
              <label>Description:</label>
              <textarea
                style={{ height: "38px" }}
                value={videoDetails.description}
                onChange={(e) =>
                  setVideoDetails({
                    ...videoDetails,
                    description: e.target.value,
                  })
                }
                required
                className="half-width"
              ></textarea>
            </div>

            <div className="form-column">
              <label>Tags (comma separated):</label>
              <input
                style={{ height: "38px" }}
                type="text"
                value={videoDetails.tags}
                onChange={(e) =>
                  setVideoDetails({ ...videoDetails, tags: e.target.value })
                }
                className="half-width"
              />
            </div>
            <div className="form-column">
              <label>Video File:</label>
              <input
                style={{ height: "38px" }}
                type="file"
                onChange={handleFileChange}
                required
                className="half-width"
              />
            </div>
          </div>

          {/* Privacy Section with radio buttons */}
          <div className="form-row">
            <div className="form-column">
              <label>Privacy:</label>
              <div className="privacy-radio-buttons">
                <input
                  type="radio"
                  id="public"
                  name="privacyStatus"
                  value="Public"
                  checked={privacyStatus === "Public"}
                  onChange={() => setPrivacyStatus("Public")}
                />
                <label htmlFor="public">Public</label>
                <input
                  type="radio"
                  id="private"
                  name="privacyStatus"
                  value="Private"
                  checked={privacyStatus === "Private"}
                  onChange={() => setPrivacyStatus("Private")}
                />
                <label htmlFor="private">Private</label>
                <input
                  type="radio"
                  id="unlisted"
                  name="privacyStatus"
                  value="Unlisted"
                  checked={privacyStatus === "Unlisted"}
                  onChange={() => setPrivacyStatus("Unlisted")}
                />
                <label htmlFor="unlisted">Unlisted</label>
              </div>
            </div>
            {/* Upload button added here */}
            <div className="form-column">
              <button
                type="button"
                onClick={handleUploadButtonClick}
                disabled={isUploading} // Disable button if uploading
              >
                Upload
              </button>
            </div>
            <div className="form-column"></div>
          </div>
        </form>
      )}

      {/* Upload Confirmation Popup */}
      {isUploadConfirmationVisible && (
        <div className="confirmation-popup">
          <p>Are you sure you want to upload this video?</p>
          {isUploading ? <p>Uploading: {uploadProgress}%</p> : null}
          <button onClick={handleUploadConfirm} disabled={isUploading}>
            {isUploading ? "Uploading..." : "Yes, Upload"}
          </button>
          <button onClick={handleUploadCancel}>Cancel</button>
        </div>
      )}

      {/* Delete Confirmation Popup */}
      {isDeleteConfirmationVisible && (
        <div className="confirmation-popup">
          <p>Are you sure you want to delete this video?</p>
          <button onClick={handleDeleteConfirm}>Yes, Delete</button>
          <button onClick={handleDeleteCancel}>Cancel</button>
        </div>
      )}

      {/* Search Input Field */}
      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by video title..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-input"
        />
      </div>

      {/* Video Table */}
      <table className="video-table">
        <thead className="table-header">
          <tr>
            <th>Video Title</th>
            <th>Video Icon</th>
            <th
              onClick={() => setSortOrder(sortOrder === "asc" ? "desc" : "asc")}
              style={{ cursor: "pointer" }}
            >
              Date of Upload {sortOrder === "asc" ? "↑" : "↓"}
            </th>
            <th>Privacy Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody className="table-body">
          {filteredVideos.map((video, index) => (
            <tr key={index}>
              <td>{video.title}</td>
              <td>
                <img
                  src={video.thumbnail}
                  alt={video.title}
                  className="video-thumbnail"
                  onClick={() => handleVideoClick(video.videoUrl)}
                />
              </td>
              <td>{formatDate(video.publishedAt)}</td>
              <td>{video.privacyStatus === "public" ? "Public" : "Private"}</td>
              <td>
                <button onClick={() => handleDeleteClick(video.videoUrl)}>
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default VideoTable;
