import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataTable from "./DataTable"; // Import the DataTable component for subscribers/subscriptions
import VideoTable from "./VideoTable"; // Import the VideoTable component for videos
import "./datatable.css"; // Import the datatable CSS file
import { AuthContext } from "../Context/AuthContext"; // Import the AuthContext

const beURL = import.meta.env.VITE_BEURL;

const Dashboard = () => {
  const navigate = useNavigate();
  const [subscribers, setSubscribers] = useState([]);
  const [subscriptions, setSubscriptions] = useState([]);
  const [videos, setVideos] = useState([]); // Store the user's videos
  const [showVideos, setShowVideos] = useState(false); // Track whether to show videos
  const [activeButton, setActiveButton] = useState(""); // Track which button is active

  const {user, updateUser } = useContext(AuthContext);

  // Fetch user details
  const getUser = async () => {
    try {
      const response = await axios.get(beURL + "/login/success", {
        withCredentials: true,
      });
  
      if (!response.status === 200) {
        throw new Error("Not Authorized");
      }
  
      const data = response.data;
  
      if (data.user) {
        console.log("User data from /login/success:", data.user);
        updateUser(data.user); 
      } else {
        return null;
      }
    } catch (error) {
      console.log("Error occurred in calling /login/success in Dashboard:", error);
      navigate("/login");
    }
  };
  
  useEffect(() => {
    getUser();
  }, []);


  // Fetch subscribers
  const getSubscriber = async () => {
    try {
      let allSubscribers = await axios.get(beURL + "/totalSubscribers", {
        withCredentials: true,
      });
      if (allSubscribers.data[0]) {
        const subscriberArray = allSubscribers.data;
        console.log("The subscribers are-->", subscriberArray);
        setSubscribers(subscriberArray);
      }
      console.log("The subscribers are-->", allSubscribers.data[0]);
      setActiveButton("subscribers");
      setShowVideos(false); // Hide the video table when other sections are shown
    } catch (error) {
      console.error("Error fetching subscribers:", error);
    }
  };

  // Fetch subscriptions
  const getSubscriptions = async () => {
    try {
      let allSubscriptions = await axios.get(beURL + "/totalSubscriptions", {
        withCredentials: true,
      });
      setSubscriptions(allSubscriptions.data);
      setActiveButton("subscriptions");
      setShowVideos(false); // Hide the video table when other sections are shown
    } catch (error) {
      console.error("Error fetching subscriptions:", error);
    }
  };

  // Fetch videos
  const getVideos = async () => {
    try {
      const response = await axios.get(beURL + "/getVideos", {
        withCredentials: true,
      });

      // Sort videos by publishedAt in descending order before setting state
      const sortedVideos = response.data.sort((a, b) => {
        return new Date(b.publishedAt) - new Date(a.publishedAt); // Sort in descending order
      });

      setVideos(sortedVideos);
      setActiveButton("videos");
      setShowVideos(true); // Show the video table
    } catch (error) {
      console.error("Error fetching videos:", error);
    }
  };

  return (
    user && (
      <>
        <div style={{ textAlign: "center" }}>
          <h1 style={{ marginTop: "-100px" }}>Dashboard</h1>{" "}
          {/* Reduced the marginTop here */}
        </div>

        <button
          onClick={getSubscriber}
          className={activeButton === "subscribers" ? "active-btn" : ""}
        >
          Get All Subscribers
        </button>

        <button
          onClick={getSubscriptions}
          className={activeButton === "subscriptions" ? "active-btn" : ""}
        >
          Get All Subscriptions
        </button>
        <button
          onClick={getVideos}
          className={activeButton === "videos" ? "active-btn" : ""}
        >
          See Your Videos
        </button>

        {/* Show Subscribers */}
        {activeButton === "subscribers" && (
          <div>
            <h1>
              You have got{" "}
              <b style={{ color: "#06056c", fontWeight: "bold" }}>
                {subscribers.length}
              </b>{" "}
              subscribers from this website
            </h1>
            <DataTable data={subscribers} />
          </div>
        )}

        {/* Show Subscriptions */}
        {activeButton === "subscriptions" && (
          <div>
            <h1>
              You have{" "}
              <b style={{ color: "#06056c", fontWeight: "bold" }}>
                {subscriptions.length}
              </b>{" "}
              subscriptions
            </h1>
            <DataTable data={subscriptions} />
          </div>
        )}

        {/* Show Videos */}
        {showVideos && activeButton === "videos" && (
          <div>
            <h1 style={{ fontSize: "25px", fontWeight: "bold", color: "#333" }}>
              Your Videos
            </h1>
            <VideoTable videos={videos} />
          </div>
        )}
      </>
    )
  );
};

export default Dashboard;
