import { useState, useContext, useEffect } from "react";
import { ChatContext } from "../../Context/ChatContext";
import { AuthContext } from "../../Context/AuthContext";
import { unreadNotificationsFunc } from "../../Utils/unreadNotifications";
import moment from "moment";

const Notification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isNotificationPresent, setIsNotificationPresent] = useState(false);
  const { user } = useContext(AuthContext);
  const {
    notifications,
    userChats,
    allUsers,
    markAllNotificationAsRead,
    markNotificationAsRead,
  } = useContext(ChatContext);
  const unreadNotifications = unreadNotificationsFunc(notifications);
  const mofifiedNotifications = notifications.map((n) => {
    const sender = allUsers.find((u) => u._id === n.senderId);
    return {
      ...n,
      senderName: sender?.displayName,
    };
  });

  useEffect(() => {
    if (unreadNotifications?.length > 0) {
      setIsNotificationPresent(true);
    } else {
      setIsNotificationPresent(false);
    }
  }, [unreadNotifications]);

  return (
    <div className="notifications" onClick={() => setIsOpen(!isOpen)}>
      {isNotificationPresent && (
        <div className="notifications-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            class="bi bi-chat-left-fill"
            viewBox="0 0 16 16"
          >
            <path d="M2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
          </svg>
          {unreadNotifications?.length === 0 ? null : (
            <span className="notification-count">
              <span>{unreadNotifications?.length}</span>
            </span>
          )}
        </div>
      )}
      {isOpen ? (
        <div className="notifications-box">
          <div className="notification-header">
            <h3>Notifications</h3>
            <div
              className="mark-as-read"
              onClick={() => markAllNotificationAsRead(notifications)}
            >
              Mark all as read
            </div>
          </div>
          {mofifiedNotifications?.length === 0 ? (
            <span>No notification yet...</span>
          ) : null}
          {mofifiedNotifications &&
            mofifiedNotifications.map((n, index) => {
              return (
                <div
                  key={index}
                  className={
                    n.isRead ? "notification" : "notification not-read"
                  }
                  onClick={() => {
                    markNotificationAsRead(n, userChats, user, notifications);
                    setIsOpen(false);
                  }}
                >
                  <span>{`${n.senderName} sent you a new message`}</span>
                  <span className="notification-time">
                    {moment(n.date).calendar()}
                  </span>
                </div>
              );
            })}
        </div>
      ) : null}
    </div>
  );
};
export default Notification;
