import "./App.css";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard";
import Error from "./Components/Error";
import NavBar from "./Components/NavBar";
import VideoPane from "./Components/VideoPane";
import { Routes, Route, useLocation } from "react-router-dom";
import Chat from "./Pages/Chat";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container } from "react-bootstrap";
import { AuthContext } from "./Context/AuthContext";
import { useContext } from "react";
import { ChatContextProvider } from "./Context/ChatContext";

function App() {
  const { user } = useContext(AuthContext);
  const location = useLocation(); // Get the current route

  // Only show VideoPane if the current route is not '/chat'
  const showVideoPane = location.pathname !== "/chat";

  return (
    <Container>
      <ChatContextProvider user={user}>
        <NavBar />
        <div className="app-container">
          <div className="main-content">
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/login" element={<Login />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/chat" element={<Chat />} />
              <Route path="*" element={<Login />} />
            </Routes>
          </div>
          {showVideoPane && <VideoPane />} {/* Render VideoPane only if not on /chat */}
        </div>
      </ChatContextProvider>
    </Container>
  );
}

export default App;
